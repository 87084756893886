<listo-radios i18n-label label="Gender" [options]="genders" [form]="form" name="sex" class="three-cols"></listo-radios>
<listo-radios i18n-label label="Marital status" [options]="civilStatuses" [form]="form" name="marital" class="three-cols"></listo-radios>
<listo-select *ngIf="shouldShowOtherCivilStatuses()" i18n-label label="Specify marital status" [options]="otherCivilStatuses" [form]="form" name="marital" [required]="true"></listo-select>
<listo-input i18n-label label="Age" [form]="form" name="age" [required]="true" type="number"></listo-input>
<listo-input *ngIf="showZipCode" i18n-label label="Zip code" [form]="form" name="zip_code" [required]="true" type="number"></listo-input>
<listo-select i18n-label label="License type" [options]="licenseTypes" [form]="form" name="license_type" [required]="true"></listo-select>


<div class="row" style="display: flex; justify-content: center;">
  <div class="col-lg-8" style="width:52%">
    <listo-input i18n-label label="Discount code" [form]="form" name="discount_code" [required]="true" type="text"></listo-input>
  </div>
  <div class="col-lg-4" style="width:30%; padding-top: 29px;">
    <listo-button  [disabled]="checkingCodeLoading || discount_code_is_valid" [invertColor]="true" (click)="checkDiscountCode()">
      <ng-container i18n>Apply code</ng-container>
    </listo-button>
  </div>
</div>

<div class="row" *ngIf="first_click" style="display: flex; justify-content: center; margin-bottom: 30px;">
  <span *ngIf="discount_code_is_valid" style="color: rgb(117, 163, 117); font-weight:bold">
    {{ discount_code }} <span i18n>code applied</span>
  </span>

  <span *ngIf="!discount_code_is_valid">
    {{ discount_code }} <span i18n>wrong code, try again</span>
  </span>
</div>



