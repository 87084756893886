<listo-stepper [currentStep]="0"></listo-stepper>
<listo-tabs *ngIf="form.length > 1" [tabs]="getTabs()" [selectedIndex]="currentItem"
            (change)="onTabChange($event)"></listo-tabs>
<div class="container">
  <div class="image">
    <img src="../../assets/images/car.svg">
    <span *ngIf="form.controls.length > 1" class="badge">{{currentItem + 1}}</span>
  </div>
  <h2 class="header" [ngSwitch]="form.length">
    <ng-container *ngSwitchCase="1" i18n>{{name ? name + ', w' : 'W'}}e need some information of your car</ng-container>
    <ng-container *ngSwitchCase="5" i18n>You can't add more vehicles</ng-container>
    <ng-container *ngSwitchDefault i18n>You can add up to {{5 - form.length}} more cars</ng-container>
  </h2>
  <listo-button *ngIf="form.length > 1" [flat]="true" (click)="deleteVehicle()" class="delete-button">
    <ng-container i18n>Delete</ng-container>
  </listo-button>
  <ng-container *ngFor="let vehicle of form.controls; let i = index">
    <app-vehicle-form [hidden]="i !== currentItem" [form]="vehicle"></app-vehicle-form>
  </ng-container>
  <listo-input i18n-label label="Phone number" [form]="userForm" name="phone" [mask]="phoneMask" placeholder="(123) 123-1234" [required]="true"></listo-input>
  <listo-checkbox [form]="userForm" name="serviceOptIn" warning_message="true" i18n>
    I authorize Listo Unlimited Inc. to contact me by telephone and text message at the number provided regarding this application and to inform me of other products and services including the use of automatic dialing telephone systems and pre-recorded messages.
    I understand that this consent is not a necessary condition for completing a business transaction with Listo Unlimited Inc. and that I may request a free copy of these statements and/or revoke this consent at any time by calling (408) 610-3084.
    <strong>(Optional).</strong>
  </listo-checkbox>
  <listo-button [disabled]="form.length >= 5" [invertColor]="true" (click)="addVehicle()">
    <ng-container i18n>Add another car</ng-container>
  </listo-button>
  <listo-button (click)="onNextClick()">
    <ng-container i18n>Next</ng-container>
    <span class="icon-arrow-right"></span>
  </listo-button>
  <listo-button [flat]="true" (click)="onBackClick()">
    <span class="icon-arrow-left"></span>
    <ng-container i18n>Back</ng-container>
  </listo-button>
</div>
