<img class="image" src="../../assets/images/contacto.svg">
<h2 class="header">
  <ng-container *ngIf="hasCompany" i18n>Leave us your contact information to receive more details about your quote</ng-container>
  <ng-container *ngIf="!hasCompany" i18n>Leave us your contact information and we'll contact you</ng-container>
</h2>
<form [formGroup]="form" (submit)="onNextClick($event)">
  <listo-input i18n-label label="Name" [form]="form" name="first_name" [required]="true"></listo-input>
  <listo-input i18n-label label="Last name" [form]="form" name="last_name" [required]="true"></listo-input>
  <listo-input i18n-label label="Phone number" [form]="form" name="phone" [mask]="phoneMask" placeholder="(123) 123-1234" [required]="true"></listo-input>
  <listo-select i18n-label label="Where do you hear about us?" [form]="form" name="lead_came_from" [required]="true"
                [options]="leadCameFromOptions"></listo-select>
  <listo-select i18n-label label="At what time can we contact you?" [form]="form" name="preferred_time_to_call"
                [options]="contactTimes"></listo-select>
  <listo-date-select *ngIf="shouldShowDate()" i18n-label label="When can we expect your call?" [value]="date" (change)="onDateChange($event)"></listo-date-select>
  <listo-input label="Email" [form]="form" name="email"></listo-input>
  <listo-button [disabled]="loading">
    <ng-container *ngIf="loading" i18n>Loading...</ng-container>
    <ng-container *ngIf="!loading">
      <ng-container i18n>Next</ng-container>
      <span class="icon-arrow-right"></span>
    </ng-container>
  </listo-button>
  <listo-button [flat]="true" (click)="onBackClick()" type="button">
    <span class="icon-arrow-left"></span>
    <ng-container i18n>Back</ng-container>
  </listo-button>
</form>
