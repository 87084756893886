<span>
  <button (click)="privacyModalOpen = true" i18n>Privacy Policy</button>
</span>
<div class="icons-container">
  <!-- <span>
    Insurance sold by Avanza Auto Insurance Services, CA License #0136157
  </span> -->
  <a href="https://www.facebook.com/listofin" target="_blank">
    <img src="./assets/images/facebook.svg">
  </a>
  <listo-phone-link>
    <img src="./assets/images/phone.svg">
  </listo-phone-link>
  <a href="mailto:CONTACT@LISTOFIN.COM">
    <img src="./assets/images/email.svg">
  </a>
</div>
<listo-privacy-modal [open]="privacyModalOpen" (close)="privacyModalOpen = false"></listo-privacy-modal>
