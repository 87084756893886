import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { ListoCommonModule } from './listo-common/listo-common.module';

import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { VehicleConfigComponent } from './vehicle-config/vehicle-config.component';
import { VehicleFormComponent } from './vehicle-form/vehicle-form.component';
import { DriverFormComponent } from './driver-form/driver-form.component';
import { DriverConfigComponent } from './driver-config/driver-config.component';
import { QuotesConfigComponent } from './quotes-config/quotes-config.component';
import { ItemsCardComponent } from './items-card/items-card.component';
import { VehicleItemComponent } from './vehicle-item/vehicle-item.component';
import { DriverItemComponent } from './driver-item/driver-item.component';
import { RateCardComponent } from './rate-card/rate-card.component';
import { VehicleRateDetailsComponent } from './vehicle-rate-details/vehicle-rate-details.component';
import { CoveragesModalComponent } from './coverages-modal/coverages-modal.component';
import { PersonalInfoConfigComponent } from './personal-info-config/personal-info-config.component';
import { GoodbyeComponent } from './goodbye/goodbye.component';
import { SentryErrorHandlerService } from './listo-common/sentry-error-handler.service';
import { LandingComponent } from './landing/landing.component';
import { ApplicationComponent } from './application/application.component';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    VehicleConfigComponent,
    VehicleFormComponent,
    DriverFormComponent,
    DriverConfigComponent,
    QuotesConfigComponent,
    ItemsCardComponent,
    VehicleItemComponent,
    DriverItemComponent,
    RateCardComponent,
    VehicleRateDetailsComponent,
    CoveragesModalComponent,
    PersonalInfoConfigComponent,
    GoodbyeComponent,
    LandingComponent,
    ApplicationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ListoCommonModule,
    RecaptchaModule,
  ],
  providers: [{ provide: ErrorHandler, useClass: SentryErrorHandlerService }],
  bootstrap: [AppComponent],
})
export class AppModule {}
