export const LANGUAGE = 'es';
export const MAPS_API_KEY = 'AIzaSyCxJR9snzhfGfltMs3zQMg0eONP3ZhB5q8';
export const SEGMENT_API_KEY = '3K5AnU0SRXb2Nhbho69WvVLYn2UuWxQd';
export const isPPC = new URLSearchParams(window.location.search).get('sid') === 'ppc';
export const PHONE_NUMBER = isPPC ? '1 (800) 931-8072' : '1 (800) 788-3155';
export const PHONE_NUMBER_EVENT = 'ClickedPhoneNumber';
export const VEHICLE_TAB_NAME = 'Vehículo';
export const STEPS = [
  {
    label: 'Vehículo',
    path: 'vehicle',
  },
  {
    label: 'Conductor',
    path: 'driver',
  },
  {
    label: 'Tarifas',
    path: 'quotes',
  },
];
export const GENDERS = [
  {
    value: 'M',
    label: 'Hombre',
  },
  {
    value: 'F',
    label: 'Mujer',
  },
  {
    value: '',
    label: 'Otro',
  },
];

export const CIVIL_STATUSES = [
  {
    value: 'M',
    label: 'Casado',
  },
  {
    value: 'S',
    label: 'Soltero',
  },
  {
    value: '',
    label: 'Otro',
  },
];

export const OTHER_CIVIL_STATUSES = [
  {
    value: 'D',
    label: 'Divorciado',
  },
  {
    value: 'E',
    label: 'Separado',
  },
  {
    value: 'W',
    label: 'Viudo',
  },
];

export const DRIVER_TAB_NAME = 'Conductor';

export const LICENSE_TYPES = [
  {
    value: 'us_license',
    label: 'Licencia americana',
  },
  {
    value: 'mx_license',
    label: 'Licencia mexicana',
  },
  {
    value: 'ca_license',
    label: 'Licencia canadiense',
  },
  {
    value: 'int_license',
    label: 'Licencia internacional',
  },
  {
    value: 'mat_license',
    label: 'Matrícula',
  },
  {
    value: 'oth_license',
    label: 'Otra',
  },
];

export const COVERAGE_TYPES = [
  {
    value: undefined,
    label: 'No cubrir',
  },
  {
    value: 'basic',
    label: 'Básica',
  },
  {
    value: 'medium',
    label: 'Mediana',
  },
  {
    value: 'max',
    label: 'Completa',
  },
];

export const CONTACT_TIMES = [
  {
    value: 'morning',
    label: 'Por la mañana',
  },
  {
    value: 'afternoon',
    label: 'Por la tarde',
  },
  {
    value: 'night',
    label: 'Por la noche',
  },
  {
    value: 'i_call',
    label: 'Prefiero yo llamarlos',
  },
];

export const YESNO = [
  {
    value: true,
    label: 'Si',
  },
  {
    value: false,
    label: 'No',
  },
];

export const LEAD_CAME_FROM_OPTIONS = [
  "Univision",
  "Familiar/Amigo",
  "Búsqueda en línea",
  "Facebook",
  "Radio",
  "Otro"
];
