<listo-stepper [currentStep]="2"></listo-stepper>
<img class="header-image" src="../../assets/images/tarifas.svg">
<h2 class="header" i18n>
  Explore options and find the best rates!
</h2>
<div class="main-container">
  <div class="item-cards-container">
    <app-items-card header="Change the coverage" i18n-header>
      <app-vehicle-item
        *ngFor="let vehicle of vehicles; let i = index;"
        [vehicle]="vehicle"
        (delete)="onVehicleDelete(i)"
        (edit)="onVehicleEdit(i)"
        (change)="onVehicleChange(i,$event)"
        [required]="isVehicleRequired(vehicle)"
      ></app-vehicle-item>
      <div class="card-buttons-container">
        <listo-button class="card-button" [flat]="true" (click)="onVehicleAdd()">
          <ng-container i18n>Add another vehicle</ng-container>
        </listo-button>
        <listo-button [flat]="true" class="card-button" (click)="coveragesOpen = true">
          <ng-container i18n>See the coverages</ng-container>
        </listo-button>
      </div>
    </app-items-card>
    <app-items-card header="Select drivers" i18n-header>
      <app-driver-item
        *ngFor="let driver of drivers; let i = index;"
        [driver]="driver"
        (delete)="onDriverDelete(i)"
        (edit)="onDriverEdit(i)"
        (change)="onDriverChange(i,$event)"
        [required]="isDriverRequired(driver)"
      ></app-driver-item>
      <listo-button class="card-button" [flat]="true" (click)="onDriverAdd()">
        <ng-container i18n>Add another driver</ng-container>
      </listo-button>
    </app-items-card>
    
    <listo-button [flat]="false" [bold]="true" (click)="goToIlending()">
      <ng-container i18n>Refinance Automobile</ng-container>
    </listo-button>

    <div class="row" *ngIf="drivers[0].discount_code_is_valid"  style="display: flex; justify-content: center;">
      <p>
        <i class="fa-solid fa-tag"  style="font-size: 100px;
            color: #f58d2e">
            <span style="
              font-size: 13px;
              color: white;
              display: flex;
              margin-top: -52px;
              margin-left: 15px;">
              <span>$10 off</span>
            </span>
        </i>
      </p>      
    </div>

    <div class="row" *ngIf="drivers[0].discount_code_is_valid"  style="display: flex; justify-content: center;">
      <p>
        <span style="
        font-size: 20px;
        color: #80838b;
        font-weight: bold">
          Cupon: {{ drivers[0].discount_code }}
        </span>
      </p>
    </div>

  </div>
  <div class="rates-container">
    <p *ngIf="loading && rates.length > 0" class="small-loading-container">
      <listo-spinner [size]="20"></listo-spinner>
      <ng-container>{{ratesProgress | percent}} </ng-container>
      <ng-container i18n>of loaded rates</ng-container>
    </p>
    <app-rate-card *ngFor="let rate of rates" [rate]="rate" (success)="onNextClick(rate)"></app-rate-card>
    <div class="empty" *ngIf="loading && rates.length === 0">
      <listo-spinner [size]="50"></listo-spinner>
      <h3 i18n>One moment please.</h3>
      <h3 i18n>We are searching the best rates for you.</h3>
    </div>
    <div class="empty" *ngIf="!loading && rates.length === 0">
      <h3 i18n>Please contact us for more information on your quote</h3>
      <listo-button (click)="onNextClick()">
        <ng-container i18n>Contact an agent</ng-container>
      </listo-button>
    </div>
    <div class="buttons-container">
      <listo-button class="back-button" [flat]="true" (click)="onBackClick()">
        <span class="icon-arrow-left"></span>
        <ng-container i18n>Back</ng-container>
      </listo-button>
      <div *ngIf="rates.length > 0">
        <h3 i18n>You didn't find what you were looking for?</h3>
        <listo-button [invertColor]="true" (click)="onNextClick()">
          <ng-container i18n>Contact an agent</ng-container>
        </listo-button>
      </div>
    </div>
  </div>
</div>
<app-coverages-modal [open]="coveragesOpen" (close)="coveragesOpen = false"></app-coverages-modal>
