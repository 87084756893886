import { Observable } from 'rxjs';

export interface OptionObject {
  label: string;
  value: any;
}

export type Option = OptionObject | string;

export type Coverage = 'basic' | 'medium' | 'max';

export interface Vehicle {
  year: string;
  maker: string;
  model: string;
  annual_miles: number;
  coverage?: Coverage;
  exclude?: boolean;
  currently_paying_for_vehicle?: boolean;

}

export interface Driver {
  sex: 'M' | 'F';
  marital: 'S' | 'M';
  age: number;
  zip_code: string;
  license_type: 'us_license' | 'mx_license' | 'ca_license' | 'int_license' | 'mat_license' | 'oth_license';
  exclude: boolean;
  is_insured: 1 | 0;
  discount_code: string;
  discount_code_is_valid: boolean;
}

export interface UserData {
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  preferred_time_to_call?: string;
  company_name?: string;
  company_price?: string;
  customer_id?: string;
  transaction_id?: string;
  receive_documents_authorized?: string;
  contact_information_authorized?: string;
  call_date?: string;
  sid?: string;
  cid?: string;
  vehicles?: Vehicle[];
  drivers?: Driver[];
  referrer?: string;
  url_params?: any;
  policyId?: string;
  driversConfigVisited?: boolean;
  quotesConfigVisited?: boolean;
  lead_came_from?: string;
  serviceOptIn?: boolean;
}

interface VehicleRateCoverage {
  CoverageName: 'BI' | 'PD' | 'UM' | 'UMPD' | 'COLL' | 'COMP';
  Limit: number[];
  Deductible: number;
  Premium: number;
}

export interface VehicleRate extends Vehicle {
  Coverages?: VehicleRateCoverage[];
  coverages: VehicleRateCoverage[];
}

export interface Rate {
  name: string;
  monthly_payment: string;
  logo: string;
  vehicles: VehicleRate[];
}

export interface Store {
  short_name: string;
  longitude: string;
  latitude: string;
  address: string;
  phone_number: string;
}

export class SegmentAnalytics extends Array {
  SNIPPET_VERSION = '4.1.0';
  methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ];

  constructor() {
    super();
    this.methods.forEach(method => {
      this[method] = this.factory(method);
    });
  }

  factory = (method) => (...args) => {
    this.push([method, ...args]);
    return this;
  };

  user = () => ({
    anonymousId: () => '',
  });

  // @ts-ignore
  trackSubmit(...args: any[]);

  // @ts-ignore
  trackClick(...args: any[]);

  // @ts-ignore
  trackLink(...args: any[]);

  // @ts-ignore
  trackForm(...args: any[]);

  // @ts-ignore
  pageview(...args: any[]);

  // @ts-ignore
  identify(...args: any[]);

  // @ts-ignore
  reset(...args: any[]);

  // @ts-ignore
  group(...args: any[]);

  // @ts-ignore
  track(...args: any[]);

  // @ts-ignore
  ready(...args: any[]);

  // @ts-ignore
  alias(...args: any[]);

  // @ts-ignore
  debug(...args: any[]);

  // @ts-ignore
  page(...args: any[]);

  // @ts-ignore
  once(...args: any[]);

  // @ts-ignore
  off(...args: any[]);

  // @ts-ignore
  on(...args: any[]);
}

export type Request = (...args: any[]) => Observable<any>;
