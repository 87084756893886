import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'listo-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() label?: string;
  @Input() value?: boolean;
  @Input() form?: FormGroup;
  @Input() name?: string;
  @Input() disabled?: boolean;
  @Input() warning_message?: boolean;
  @Output() change = new EventEmitter<boolean>();

  onChange() {
    const value = this.getValue();
    this.change.emit(!value);
    if (this.form && this.name) {
      this.form.patchValue({
        [this.name]: !value,
      });
      this.form.get(this.name).markAsTouched();
    }
  }

  getValue() {
    if (this.form && this.name) {
      return this.form.get(this.name).value;
    }
    return this.value;
  }

  get error() {
    if (!this.form || !this.name) {
      return false;
    }
    const control = this.form.get(this.name);
    return control && control.touched && control.errors && Object.keys(control.errors)[0];
  }

  get warning() {
    if (!this.form || !this.name) {
      return false;
    }
    const control = this.form.get(this.name);
    return this.warning_message && !control.value;
  }
}
