<a routerLink="/" class="link">
  <img class="logo" src="../../../assets/images/logo-listo.png" alt="listo">
  <!-- <span style="font-size: 25px;font-style: oblique;">
    Avanza Auto Insurance
  </span> -->
</a>
<div *ngIf="!authenticated">
  <div i18n>How can we help you?</div>
  <div><listo-phone-link class="link"></listo-phone-link></div>
</div>
<listo-agent-session-button *ngIf="authenticated"></listo-agent-session-button>

