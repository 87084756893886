import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataStorageService } from '../data-storage.service';
import { Driver, Rate, UserData, Vehicle } from '../../utils/types';
import { WebServicesService } from '../web-services.service';
import { Subscription } from 'rxjs';
import {
  filterDrivers,
  filterVehicles,
  getSegmentPage,
  mapDrivers,
  mapRateToSegmentProduct,
} from '../../utils/operations';
import { SegmentService } from '../segment.service';

@Component({
  selector: 'app-quotes-config',
  templateUrl: './quotes-config.component.html',
  styleUrls: ['./quotes-config.component.scss'],
})
export class QuotesConfigComponent implements OnInit {
  vehicles: Vehicle[];
  drivers: Driver[];
  rates: Rate[] = [];
  customer_id: string;
  transaction_id: string;
  loading = false;
  coveragesOpen = false;
  request = new Subscription();
  ratesProgress = 0;

  constructor(
    private router: Router,
    private storage: DataStorageService,
    private webServices: WebServicesService,
    private segment: SegmentService,
  ) {}

  ngOnInit() {
    const data = this.storage.getData();
    if (data) {
      this.setComponentData(data);
    } else {
      this.router.navigate([''], { queryParamsHandling: "preserve" } );
    }
  }

  setComponentData({ vehicles = [], drivers = [] }: UserData) {
    this.vehicles = vehicles;
    this.drivers = drivers;
    this.getRates(true);
    this.storage.patchData({ quotesConfigVisited: true });
  }

  getRates(firstTime?: boolean) {
    this.request.unsubscribe();
    this.loading = true;
    this.rates = [];
    this.request = this.webServices.getRates(
      this.vehicles.filter(filterVehicles),
      this.drivers.filter(filterDrivers).map(mapDrivers),
    ).subscribe(
      ({ rates, customer_id, transaction_id, progress }) => {
        this.rates = rates;
        this.customer_id = customer_id;
        this.transaction_id = transaction_id;
        this.ratesProgress = progress;
      },
      () => {
        this.rates = [];
        this.customer_id = undefined;
        this.transaction_id = undefined;
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.trackRates(firstTime);
      },
    );
  }

  onVehicleDelete(index: number) {
    this.vehicles.splice(index, 1);
    this.getRates();
  }

  onVehicleEdit(index: number) {
    this.router.navigate(['vehicle'], {
      queryParams: {
        currentItem: index,
      },
    });
  }

  onVehicleAdd() {
    this.router.navigate(['vehicle'], {
      queryParams: {
        new: true,
      },
    });
  }

  onVehicleChange(index: number, vehicle: Vehicle) {
    this.vehicles[index] = vehicle;
    this.getRates();
  }

  onDriverDelete(index: number) {
    this.drivers.splice(index, 1);
    this.getRates();
  }

  onDriverEdit(index: number) {
    this.router.navigate(['driver'], {
      queryParams: {
        currentItem: index,
      },
      queryParamsHandling: "preserve"
    });
  }

  onDriverAdd() {
    this.router.navigate(['driver'], {
      queryParams: {
        new: true,
      },
      queryParamsHandling: "preserve"
    });
  }

  onDriverChange(index: number, driver: Driver) {
    this.drivers[index] = driver;
    this.getRates();
  }

  onNextClick(rate?: Rate) {
    this.storage.patchData({
      company_name: rate ? rate.name : undefined,
      company_price: rate ? rate.monthly_payment : undefined,
      customer_id: this.customer_id,
      transaction_id: this.transaction_id,
      vehicles: this.vehicles,
      drivers: this.drivers,
    });
    if (rate) {
      this.segment.track('Product Clicked', mapRateToSegmentProduct(rate));
    } else {
      this.segment.track(`${getSegmentPage()} AgentSelected`);
    }
    this.router.navigate(['personal'], { queryParamsHandling: "preserve" });
  }

  onBackClick() {
    this.router.navigate(['driver'], { queryParamsHandling: "preserve" });
  }

  isVehicleRequired(vehicle: Vehicle): boolean {
    return vehicle.coverage && this.vehicles.filter(filterVehicles).length === 1;
  }

  isDriverRequired(driver: Driver): boolean {
    return !driver.exclude && this.drivers.filter(filterDrivers).length === 1;
  }

  trackRates(firstTime?: boolean) {
    this.segment.track(`Product List ${firstTime ? 'Viewed' : 'Filtered'}`, {
      list_id: this.transaction_id,
      filters: [{
        ...this.vehicles.map(vehicle => ({
          type: 'vehicle',
          value: vehicle,
        })),
        ...this.drivers.map(driver => ({
          type: 'driver',
          value: driver,
        })),
      }],
      products: this.rates.map(mapRateToSegmentProduct),
    });
  }

  goToIlending() {
    const url = 'https://listofin.com/es/auto-refinance';
    window.open(url, '_blank');
  }
}


