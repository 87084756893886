<div class="container" [class.disabled]="disabled" (click)="!disabled && onChange()">
  <img src="../../assets/images/checkbox{{getValue() ? '-selected' : ''}}{{disabled ? '-disabled' : ''}}.svg">
  <span>{{label}}<ng-content></ng-content></span>
</div>
<div *ngIf="error" [ngSwitch]="error" class="error-message">
  <span class="icon-error"></span>
  <ng-container *ngSwitchCase="'required'" i18n>This field is required</ng-container>
  <ng-container *ngSwitchDefault i18n>Invalid field</ng-container>
</div>
<div *ngIf="warning" [ngSwitch]="warning" class="warning-message">
  <span class="icon-arrow-right"></span>
  <ng-container *ngSwitchDefault i18n>Opting in will allow you to stay in the know of any important changes or new products.</ng-container>
</div>