import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WebServicesService } from '../web-services.service';
import { YESNO } from '../../utils/constants';
import { Option } from '../../utils/types';

export class VehicleForm extends FormGroup {
  constructor() {
    super({
      year: new FormControl('', Validators.required),
      maker: new FormControl('', Validators.required),
      model: new FormControl('', Validators.required),
      annual_miles: new FormControl('13000', Validators.required),
    });
  }
}

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss'],
})
export class VehicleFormComponent implements OnInit {
  @Input() form: VehicleForm;

  years: string[] = [];
  makers: string[] = [];
  models: string[] = [];
  yearsLoading = false;
  makersLoading = false;
  modelsLoading = false;
  yesno: Option[] = YESNO;

  constructor(private webServices: WebServicesService) { }

  ngOnInit() {
    this.subscribeToChanges();
    this.getYears();
    this.getMakers();
    this.getModels();
  }

  subscribeToChanges() {
    this.form.get('year').valueChanges.subscribe(
      () => {
        this.form.patchValue({
          maker: '',
          model: '',
        });
        this.getMakers();
      },
    );

    this.form.get('maker').valueChanges.subscribe(
      () => {
        this.form.patchValue({
          model: '',
        });
        this.getModels();
      },
    );
  }

  getYears() {
    this.yearsLoading = true;
    this.webServices.getVehicleYears().subscribe(
      ({ years }) => {
        this.yearsLoading = false;
        this.years = years;
      },
    );
  }

  getMakers() {
    const year = this.form.get('year').value;
    if (year) {
      this.makersLoading = true;
      this.webServices.getVehicleMakers(year).subscribe(
        ({ makers }) => {
          this.makersLoading = false;
          this.makers = makers;
        },
      );
    }
  }

  getModels() {
    const year = this.form.get('year').value;
    const maker = this.form.get('maker').value;
    if (year && maker) {
      this.modelsLoading = true;
      this.webServices.getVehicleModels(year, maker).subscribe(
        ({ models }) => {
          this.modelsLoading = false;
          this.models = models;
        },
      );
    }
  }

  shouldShowMakers() {
    const field = this.form.get('year');
    return field && field.value;
  }

  shouldShowModels() {
    const field = this.form.get('maker');
    return this.shouldShowMakers() && field && field.value;
  }

}
